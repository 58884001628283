<template>
	<el-dialog
		title=""
		:visible.sync="createdVisible"
		width="578px"
		center
		:close-on-click-modal="false"
		@close="onResetForm('ruleForm')"
		:show-close="false"
	>
		<div slot="title" class="postion">
			<i v-if="!isShowDetail" class="iconfont icon-bianji1"></i>
			<span class="download">{{ isShowDetail ? '查看' : !isShowDetail && ruleForm.id ? '编辑' : '新增' }}科室</span>
		</div>
		<el-form :model="ruleForm" :rules="isShowDetail ? {} : rules" ref="ruleForm" label-width="100px" class="demo-ruleForm" label-position="top">
			<!-- <el-form-item label="医疗机构" prop="medicalOrganizationId">
				<el-select
					:disabled="isShowDetail"
					v-model="ruleForm.medicalOrganizationId"
					placeholder="请选择"
					@change="changeSelect($event, 'medicalOrganizationId')"
				>
					<el-option v-for="(item, index) in medicalOrganizaList" :key="index" :label="item.name" :value="item.id"></el-option>
				</el-select>
			</el-form-item> -->
			<el-form-item label="科室名称" prop="name">
				<el-input v-model.trim="ruleForm.name" :disabled="isShowDetail" placeholder="请输入"></el-input>
			</el-form-item>
			<el-form-item label="科室类型" prop="departmentTypeDictionaryItemId">
				<el-select
					:disabled="isShowDetail"
					v-model="ruleForm.departmentTypeDictionaryItemId"
					placeholder="请选择"
					@change="changeSelect($event, 'departmentTypeDictionaryItemId')"
				>
					<el-option v-for="(item, index) in departmentTypeList" :key="index" :label="item.title" :value="item.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="科室位置" prop="roomLocation">
				<el-input v-model.trim="ruleForm.roomLocation" :disabled="isShowDetail" placeholder="请输入"></el-input>
			</el-form-item>
			<!-- <el-form-item prop="directorId" label="科室主任" v-if="!isShowDetail && ruleForm.id">
				<el-select :disabled="isShowDetail" v-model="ruleForm.directorId" placeholder="请选择" @change="changeSelect($event, 'directorId')">
					<el-option v-for="(item, index) in directorList" :key="index" :label="item.name" :value="item.id"></el-option>
				</el-select>
			</el-form-item> -->
			<el-form-item class="w50 mr16" prop="principalName" label="联系人">
				<el-input v-model.trim="ruleForm.principalName" :disabled="isShowDetail" placeholder="姓名"></el-input>
			</el-form-item>
			<el-form-item class="w50 mr0" prop="phoneNumber">
				<el-input v-model.trim="ruleForm.phoneNumber" :disabled="isShowDetail" maxlength="11" placeholder="联系电话"></el-input>
			</el-form-item>
			<el-form-item label="科室介绍" prop="description">
				<el-input type="textarea" autosize :disabled="isShowDetail" v-model.trim="ruleForm.description"></el-input>
			</el-form-item>
		</el-form>
		<span slot="footer" class="dialog-footer">
			<el-button @click="onResetForm('ruleForm')" type="primary" v-if="isShowDetail">确 定</el-button>
			<el-button v-else type="primary" @click="onSave('ruleForm')" :loading="saveLoading">保 存</el-button>
			<el-button v-if="!isShowDetail" @click="onResetForm('ruleForm')">取 消</el-button>
		</span>
	</el-dialog>
</template>

<script>
import { verifyPhone } from '@/utils/toolsValidate';
export default {
	data() {
		var checkPhone = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请输入联系方式'));
			} else if (!verifyPhone(value)) {
				callback(new Error('请输入正确的联系方式'));
			} else {
				callback();
			}
		};
		return {
			createdVisible: false,
			saveLoading: false,
			ruleForm: {
				name: '',
				roomLocation: '',
				principalName: '',
				phoneNumber: '',
				description: '',
				departmentTypeDictionaryItemId: '',
				// medicalOrganizationId: '',
				directorId: null,
			},
			departmentTypeList: [],
			medicalOrganizaList: [],
			directorList: [],
			rules: {
				name: [{ required: true, message: '请输入科室名称', trigger: 'blur' }],
				roomLocation: [{ required: true, message: '请输入科室位置', trigger: 'blur' }],
				principalName: [{ required: true, message: '请输入联系人姓名', trigger: 'blur' }],
				phoneNumber: [{ required: true, validator: checkPhone, trigger: 'blur' }],
				description: [{ required: true, message: '请输入科室介绍', trigger: 'blur' }],
				departmentTypeDictionaryItemId: [{ required: true, message: '请选择科室类型', trigger: 'blur' }],
				// medicalOrganizationId: [{ required: true, message: '请选择医疗机构', trigger: 'blur' }],
			},
			link: '',
		};
	},
	props: ['isShowDetail'],
	methods: {
		init({ link, row }) {
			this.createdVisible = true;
			this.getDepartmentTypeList();
			this.getMedicalOrganizaList();
			this.link = link;
			if (row) {
				this.getDetails(link);
				this.ruleForm = { ...row };
				// this.ruleForm.directorId = row.director ? row.director.id : '';
				this.ruleForm.departmentTypeDictionaryItemId = row.departmentTypeDictionaryItem.id;
				// this.ruleForm.medicalOrganizationId = row.medicalOrganization.id;
				this.$nextTick(() => {
					this.$refs.ruleForm.clearValidate();
				});
				return;
			}
			//新增
			this.ruleForm = this.$options.data().ruleForm; //清空表单
			this.$nextTick(() => {
				this.$refs.ruleForm.clearValidate();
			});
		},
		getDetails(link) {
			this.$http
				.get(link)
				.then((res) => {
					if (res.data && res.data.success) {
						this.getDirectorList(res.data.collection[0].id);
					}
				})
				.catch((e) => {});
		},
		// 获取科室类型
		getDepartmentTypeList() {
			this.$http
				.get(this.api['DictionaryItems#index'].href, { params: { dictionaryCode: 'MEDICAL_DEPARTMENT', size: 9999, current: 1 } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.departmentTypeList = res.data.collection || [];
					}
				})
				.catch((e) => {});
		},
		// 获取医疗机构
		getMedicalOrganizaList() {
			let data = {
				current: 1,
				size: 99999,
			};
			this.$http
				.get(this.api['MedicalOrganizations#index'].href, { params: data })
				.then((res) => {
					if (res.data && res.data.success) {
						this.medicalOrganizaList = res.data.collection || [];
					}
				})
				.catch((e) => {});
		},
		onResetForm(formName) {
			this.createdVisible = false;
			// this.$refs.ruleForm = {};
			this.$refs[formName].resetFields();
		},
		//保存
		onSave(formName) {
			if (!this.$route.query.id) return this.$message.error('当前科室没有绑定医疗机构');
			this.$refs[formName].validate((valid) => {
				if (valid) {
					this.saveLoading = true;
					let params = {
						...this.ruleForm,
						medicalOrganizationId: this.$route.query.id,
					};
					let queryApi = this.ruleForm.id ? this.$http.put(this.link, params) : this.$http.post(this.link, params);
					queryApi;
					queryApi
						.then((res) => {
							if (res.data && res.data.success) {
								this.$message.success(this.ruleForm.id ? '修改成功' : '添加成功');
								this.$emit('getList');
								this.saveLoading = false;
								this.onResetForm('ruleForm');
							}
						})
						.catch((e) => {
							this.saveLoading = false;
						});
				}else {
					this.$nextTick(() => {
						let isError = document.getElementsByClassName('is-error');
						// var a = $(isError[0]).find("input") //this.$refs.cmd.$el.querySelector('input')
						// a.focus()  光标定位到所在input
						isError[0].scrollIntoView({
							// 滚动到指定节点
							// 值有start,center,end，nearest
							block: 'center',
							// 值有auto、instant,smooth，缓动动画
							behavior: 'smooth',
						});
					});
					return false;
				}
			});
		},
		// 获取科室主任
		getDirectorList(id) {
			let data = {
				current: 1,
				size: 99999,
				departmentId: id,
				enabled: true,
			};
			this.$http
				.get(this.api['MedicalWorkers#index'].href, { params: data })
				.then((res) => {
					if (res.data && res.data.success) {
						this.directorList = res.data.collection || [];
					}
				})
				.catch((e) => {});
		},
		changeSelect(val, type) {
			this.ruleForm[type] = val;
			this.$forceUpdate();
		},
	},
};
</script>

<style lang="scss" scoped>
.postion {
	font-size: 18px;
	.icon-bianji1 {
		margin-right: 16px;
		color: #1db9b1;
	}
}
/deep/ .el-form {
	.el-form-item {
		.el-select,
		.el-input {
			width: 100%;
		}
	}
	.el-form-item__label {
		line-height: 14px;
		height: 14px;
		margin-bottom: 8px;
		padding: 0;
	}
}
/deep/ .el-button {
	margin-left: 16px;
}
/deep/ .mr0 {
	margin-right: 0 !important;
}
/deep/ .w50 {
	width: calc(50% - 8px);
	display: inline-block;
}
/deep/ .el-textarea__inner {
	min-height: 64px !important;
	line-height: 18px !important;
	padding: 5px 12px !important;
}
</style>